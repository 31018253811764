import { useSelector } from "react-redux";
import { RootState } from "@/stores/rootReducer";
import { useMemo } from "react";

type UseUserStatusReturn = {
  isDesigner: boolean;
  hasTeam: boolean;
};

const useUserStatus = (): UseUserStatusReturn => {
  const { user } = useSelector((state: RootState) => state.auth);
  const isDesigner = useMemo(() => {
    if (!user) return false;

    return !!user.designer;
  }, [user]);

  const hasTeam = useMemo<boolean>(() => {
    if (!user) return false;

    return user.team_members && user.team_members.length > 0;
  }, [user]);

  return {
    isDesigner,
    hasTeam,
  };
};

export default useUserStatus;
