import useUserStatus from "@/features/user/hooks/useUserStatus";
import {
  open as openModal,
  close as closeModal,
  setRedirectPath,
} from "@/stores/modalLimitFunction";
import { RootState } from "@/stores/rootReducer";
import { useRouter } from "next/router";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const useModalLimitFunction = () => {
  const router = useRouter();
  const { isDesigner } = useUserStatus();
  const { isOpen, redirectPath } = useSelector(
    (state: RootState) => state.modalLimitFunction,
  );
  const dispatch = useDispatch();

  const open = useCallback(
    (path?: string) => {
      dispatch(setRedirectPath(path || router.asPath));
      dispatch(openModal());
    },
    [dispatch, router],
  );

  const openIfNotDesigner = useCallback(
    (param: { onDesigner?: VoidFunction; path?: string }) => {
      const { onDesigner, path } = param;

      // デザイナーじゃなかったらモーダルを開く
      if (!isDesigner) {
        open(path);

        return;
      }

      onDesigner && onDesigner();
    },
    [open, isDesigner],
  );

  const close = useCallback(() => dispatch(closeModal()), [dispatch]);

  return {
    open,
    openIfNotDesigner,
    close,
    isOpen,
    redirectPath,
  };
};
export default useModalLimitFunction;
